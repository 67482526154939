import React from 'react';

import Icons from '@/core/components/Icons';

interface InfoProps {
  text: string;
}

const Info: React.FC<InfoProps> = ({ text }) => {
  return (
    <div className="flex rounded-2 bg-bg-pale p-3">
      <div className="size-6">
        <Icons color="placeholder" size="24" icon="tips-and-updates" />
      </div>
      <span className="text-caption-primary pl-2 font-normal text-content-placeholder">{text}</span>
    </div>
  );
};

export default Info;
