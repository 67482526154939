import * as yup from 'yup';

import { NOT_URL_REGEX, OTP_REGEX, PASSWORD_REGEX, USERNAME_AT_LEAST_2_REGEX, USERNAME_REGEX } from '@/core/lib/regex';
import { locationFormSchema } from '@/core/types/geo';

export const usersAuthProviderCodeFormSchema = yup.object({
  code: yup.string().optional(),
  remember: yup.boolean().optional().default(true),
});

export type UsersAuthProviderCodeForm = yup.InferType<typeof usersAuthProviderCodeFormSchema>;

export const usersPrivilegeResultSchema = yup.object({
  type: yup.number().optional(),
  label: yup.string().optional(),
});

export type UsersPrivilegeResult = yup.InferType<typeof usersPrivilegeResultSchema>;

export enum ConnectionType {
  PASSWORD = 'password',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const usersAuthResultSchema = yup.object({
  token_type: yup.string().required(),
  access_token: yup.string().required(),
  access_expires_in: yup.number().required(),
  refresh_token: yup.string().optional(),
  refresh_expires_in: yup.number().optional(),
  has_control: yup.boolean().required(),
  privileges: yup.array(usersPrivilegeResultSchema).optional(),
  // for analytics
  sign_up: yup.boolean().required(),
  connection_type: yup.mixed<ConnectionType>().required().oneOf(Object.values(ConnectionType)).required(),
});

export type UsersAuthResult = yup.InferType<typeof usersAuthResultSchema>;

export const usersAuthRecoveryEmailFormSchema = yup.object({
  email: yup.string().email('errors.inputs.email').required('errors.inputs.required'),
});

export type UsersAuthRecoveryEmailForm = yup.InferType<typeof usersAuthRecoveryEmailFormSchema>;

export type UsersAuthRecoveryEmailResult = null;

export const usersAuthRecoveryFormSchema = yup.object({
  otp: yup.string().matches(OTP_REGEX, 'errors.inputs.6-digits').required('errors.inputs.required'),
  email: yup.string().email('errors.inputs.email').required('errors.inputs.required'),
  password: yup.string().matches(PASSWORD_REGEX, 'errors.inputs.password').required('errors.inputs.required'),
});

export type UsersAuthRecoveryForm = yup.InferType<typeof usersAuthRecoveryFormSchema>;

export const usersAuthLoginFormSchema = yup.object({
  email: yup.string().email('errors.inputs.email').required('errors.inputs.required'),
  password: yup.string().required('errors.inputs.required'),
  remember: yup.boolean().optional().default(true),
});

export type UsersAuthLoginForm = yup.InferType<typeof usersAuthLoginFormSchema>;

export const usersAuthRegisterFormSchema = yup.object({
  email: yup.string().email('errors.inputs.email').required('errors.inputs.required'),
  password: yup.string().matches(PASSWORD_REGEX, 'errors.inputs.password').required('errors.inputs.required'),
  username: yup
    .string()
    .matches(USERNAME_AT_LEAST_2_REGEX, 'errors.inputs.username')
    .matches(USERNAME_REGEX, 'errors.inputs.username')
    .matches(NOT_URL_REGEX, 'errors.inputs.has_not_url')
    .required('errors.inputs.required'),
  location: locationFormSchema.required('errors.inputs.required'),
  remember: yup.boolean().optional().default(true),
  newsletter: yup.bool().oneOf([true, false]).required('errors.inputs.required'),
  cgu: yup.bool().oneOf([true], 'errors.cgu').required('errors.inputs.required'),
  origin: yup.string().nullable(),
});

export type UsersAuthRegisterForm = yup.InferType<typeof usersAuthRegisterFormSchema>;

export const usersAuthTokenFormSchema = yup.object({
  token: yup.string().required('errors.inputs.required'),
});

export type UsersAuthTokenForm = yup.InferType<typeof usersAuthTokenFormSchema>;
