import { useMemo } from 'react';

import type { CatalogueSearchSchema } from '@/core/components/CatalogueSearch/CatalogueSearchProvider';
import CatalogueDomain, { CountDomain } from '@/core/lib/new-architecture/domain/catalogue.domain';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import type { InfiniteStoreData, StoreData } from '@/core/lib/new-architecture/store/store.types';
import Routes from '@/core/lib/router/routes';
import { type DonationsPublicDonationQuery, donationsPublicDonationQuerySchema } from '@/core/types/donation';

const useCatalogue = (override?: DonationsPublicDonationQuery): InfiniteStoreData<CatalogueDomain> => {
  const { data: current, isLoading: userIsLoading } = Store.current.useCurrent();
  const { data: categories, isLoading: categoriesIsLoading } = Store.categories.useCategories();

  const params = Routes.CatalogueRoute.useParams();

  const query = donationsPublicDonationQuerySchema.cast({ ...params.page, ...params.search }, { stripUnknown: true, assert: false });

  const { data: pages, isLoading: donationsIsLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = Query.donations.useDonations({ data: override ?? query });

  const { data: loc } = Query.locs.useDetail({ data: { search: query.loc } });

  const isLoading = userIsLoading || categoriesIsLoading || donationsIsLoading;

  const data = useMemo(() => {
    if (!pages || !categories) {
      return null;
    }

    return new CatalogueDomain({ data: pages, categories, current, query: override ?? query, loc });
  }, [pages, categories, current, override, query, loc]);

  return {
    data,
    isLoading,
    isFetchingNextPage,
    notFound: false,
    hasNext: hasNextPage,
    total: pages?.pages[0].total ?? 0,
    next: () => fetchNextPage(),
  };
};

const useCount = (search: CatalogueSearchSchema | null): StoreData<CountDomain> => {
  const { data: count, isLoading } = Query.donations.useDonationsCount({
    data: {
      cat: search?.cat?.alias,
      loc: search?.loc?.loc,
      distance: search?.loc?.distance,
      title: search?.title ?? undefined,
    },
    enabled: !!search,
  });

  const data = useMemo(() => {
    if (!count) return null;

    return new CountDomain(count.total);
  }, [count]);

  return {
    data,
    isLoading,
    notFound: false,
  };
};

export default { useCatalogue, useCount };
