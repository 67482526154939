import React, { createContext, useCallback, useContext, useMemo } from 'react';

export interface BrowserContextType {
  open: (url: string) => Promise<void>;
}

export const BrowserContext = createContext<BrowserContextType | null>(null);

export const useBrowserContext = () => useContext(BrowserContext) as BrowserContextType;

const BrowserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const open = useCallback(async (url: string) => {
    Promise.resolve(window.location.assign(url));
  }, []);

  const value = useMemo(() => ({ open }) satisfies BrowserContextType, []);

  return <BrowserContext.Provider value={value}>{children}</BrowserContext.Provider>;
};

export default BrowserProvider;
