import React from 'react';

import Button from '@/core/components/Button';
import Header from '@/core/components/Header';
import Modal from '@/core/components/Modal';
import NotificationButton from '@/core/components/NotificationButton';
import Actions from '@/core/lib/new-architecture/actions';
import type SavedSearchDomain from '@/core/lib/new-architecture/domain/savedSearch.domain';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface SavedSearchModalProps {
  isOpen: boolean;
  savedSearch: SavedSearchDomain | null;
  onClose: () => void;
}

const SavedSearchModal: React.FC<SavedSearchModalProps> = ({ isOpen, onClose, savedSearch }) => {
  const { t } = useTranslationContext(['common']);
  const { push: pushRoute } = useRouterContext();

  const { onToggleEmail, isToggleEmailLoading, onTogglePush, isTogglePushLoading } = Actions.savedSearch.useSavedSearch(savedSearch);

  if (!savedSearch) {
    return null;
  }

  const { email, push, label } = savedSearch.getSettings();

  const onClickPush = () => onTogglePush(!push);
  const onClickEmail = () => onToggleEmail(!email);

  const onGoToFavorites = () => {
    onClose();
    pushRoute(new Routes.FavoritesRoute());
  };

  return (
    <Modal type="tunnel" isOpen={isOpen} onClose={onClose}>
      <Header content={t('components.saved-search-modal.title', { ns: 'common' })} iconAfter="close" onAfter={onClose} />
      <div className="flex flex-col items-center gap-6">
        <p className="text-body-secondary mr-3 grow font-medium text-content-primary">{t('components.saved-search-modal.text', { ns: 'common' })}</p>

        <div className="flex w-full items-center">
          <p className="text-body-secondary mr-3 grow font-medium text-content-primary">{t('components.saved-search-modal.notifications', { ns: 'common' })}</p>

          <div className="flex gap-4">
            <NotificationButton
              isLoading={isTogglePushLoading}
              isSelected={push}
              iconName="smartphone"
              onClick={onClickPush}
              ariaLabel={t(`search-card.push.aria-${push ? 'disable' : 'able'}`, { ns: 'favorites', label })}
            />
            <NotificationButton
              isLoading={isToggleEmailLoading}
              isSelected={email}
              iconName="mail"
              onClick={onClickEmail}
              ariaLabel={t(`components.saved-search-modal.email.aria-${email ? 'disable' : 'able'}`, { ns: 'common', label })}
            />
          </div>
        </div>

        <div className="w-full md:w-3/4 md:min-w-fit">
          <Button variant="secondary" size="large" onClick={onGoToFavorites}>
            {t('components.saved-search-modal.go-to-favorites', { ns: 'common' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SavedSearchModal;
