import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import { LocDomain } from '@/core/lib/new-architecture/domain/locs.domain';
import type { UsersContactsResult } from '@/core/types/contact';
import type { CityResult, CountryResult, PointResult, SubResult } from '@/core/types/geo';
import type { UsersPrivateProfilResult } from '@/core/types/profile';
import type { UserControlResult, UserResult } from '@/core/types/users';

export interface RelatedUser {
  uuid: string;
  username: string;
  state: string;
  avatarUrl: string;
  donationDonated: number;
  donationReceived: number;
  isNice: boolean;
  isPunctual: boolean;
  co2Given: number;
  co2Received: number;
  createdAt: Dayjs;
}

const userResultToRelatedUser = (user: UserResult): RelatedUser => ({
  ...user,
  avatarUrl: user.avatar_url,
  donationDonated: user.donation_donated,
  donationReceived: user.donation_received,
  isNice: user.is_nice,
  isPunctual: user.is_punctual,
  co2Given: user.co2_given,
  co2Received: user.co2_received,
  createdAt: dayjs(user.created_at),
});

const getUsers = (type: 'block' | 'follow', contacts?: UsersContactsResult | null): RelatedUser[] => {
  return (contacts?.contacts ?? []).reduce((acc, contact) => {
    if (contact.type === (type === 'block' ? 2 : 1)) {
      const user = (contacts?.users ?? []).find(u => u.uuid === contact.for_user);

      if (!user) {
        return acc;
      }

      return [...acc, userResultToRelatedUser(user)];
    }

    return acc;
  }, [] as RelatedUser[]);
};

interface CurrentDomainConstructor {
  data: UsersPrivateProfilResult;
  control?: UserControlResult | null;
  contacts?: UsersContactsResult | null;
  loc?: LocDomain | null;
}

interface NotificationParams {
  newsletters: {
    email: boolean;
  };
  conversations: {
    email: boolean;
    push: boolean;
  };
}

interface CurrentInfo {
  avatarUrl: string;
  username: string;
  co2: number;
  email: string;
  signature: string | null;
}

export interface CurrentDomainInterface {
  uuid: string;

  hasBlocked: (uuid: string) => boolean;
  getNotificationParams: () => NotificationParams;
  getInfo: () => CurrentInfo;
  getCityOrSubResult: () => SubResult | CityResult | null;
}

class CurrentDomain implements CurrentDomainInterface {
  private loc: LocDomain | null;

  public data: UsersPrivateProfilResult;

  public control: UserControlResult;

  public blockedUsers: RelatedUser[];

  public followedUsers: RelatedUser[];

  constructor({ data, control, contacts, loc }: CurrentDomainConstructor) {
    this.data = data;
    this.control = control ?? null;
    this.loc = loc ?? null;
    this.blockedUsers = getUsers('block', contacts);
    this.followedUsers = getUsers('follow', contacts);
  }

  get uuid() {
    return this.data.uuid;
  }

  public hasBlocked(uuid: string) {
    return !!this.blockedUsers.find(user => user.uuid === uuid);
  }

  public getNotificationParams(): NotificationParams {
    return {
      newsletters: {
        email: !!this.data.newsletter,
      },
      conversations: {
        email: !!this.data.msg_email,
        push: !!this.data.msg_push,
      },
    };
  }

  public getInfo(): CurrentInfo {
    return {
      avatarUrl: this.data.avatar_url,
      username: this.data.username,
      co2: this.data.co2_given + this.data.co2_received,
      email: this.data.email,
      signature: this.data.signature ?? null,
    };
  }

  public getCityOrSubResult(): SubResult | CityResult | null {
    const sub = this.data.subs?.find(s => s.id === this.data.sub) ?? null;
    const city = this.data.cities?.find(c => c.id === this.data.city) ?? null;
    return sub ?? city;
  }

  public getCityResult(): CityResult | null {
    const city = this.data.cities?.find(c => c.id === this.data.city) ?? null;
    return city;
  }

  public getLoc(): LocDomain | null {
    if (this.loc) {
      return this.loc;
    }

    const userCity = this.data.cities?.find(c => c.id === this.data.city) ?? null;
    const userSub = this.data.subs?.find(c => c.id === this.data.sub) ?? null;

    if (!userCity) {
      return null;
    }

    const userAdmin = this.data.admins?.find(admin => admin.id === userCity?.admin);
    const userCountry = this.data.countries?.find(country => country.id === userCity?.country) as CountryResult;

    return new LocDomain({
      data: {
        id: this.data.city.toString(),
        country: userCountry,
        admin: userAdmin,
        city: userCity,
        sub: userSub,
      },
    });
  }

  public getLatLon(): PointResult | null {
    if (this.loc) {
      return this.loc.getLatLon();
    }
    return null;
  }
}

export default CurrentDomain;
