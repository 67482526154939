import Head from 'next/head';
import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';

import useTimeout from '@/core/hooks/useTimeout';
import type { AdProps, AdSize } from '@/core/lib/ads/ads.context';
import { AdsContext, BannerSlot, useAdsContext } from '@/core/lib/ads/ads.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import observe from '@/core/lib/intersection/observe';
import { useRouterContext } from '@/core/lib/router/router.context';

const GOOGLE_AD_CLIENT = 'ca-pub-5676083956269971';

const sizes = {
  '728x90': { width: 728, heightAndWidth: 'w-[728px] h-[90px]' },
  '728x480': { width: 728, heightAndWidth: 'w-[728px] h-[480px]' },
  '336x280': { width: 336, heightAndWidth: 'w-[336px] h-[280px]' },
  '336x600': { width: 336, heightAndWidth: 'w-[336px] h-[600px]' },
  '320x100': { width: 320, heightAndWidth: 'w-[320px] h-[100px]' },
  '300x600': { width: 300, heightAndWidth: 'w-[300px] h-[600px]' },
  '300x250': { width: 300, heightAndWidth: 'w-[300px] h-[250px]' },
  '160x600': { width: 160, heightAndWidth: 'w-[160px] h-[600px]' },
} satisfies { [key in AdSize]: { width: number; heightAndWidth: string } };

const triggerAd = (page: boolean = false) => {
  try {
    if (typeof window === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(page ? { google_ad_client: GOOGLE_AD_CLIENT, enable_page_level_ads: true } : {});
      // setIsClient(true);
    }
  } catch {
    // pass
  }
};

const inferAdSlot = (adSlot: BannerSlot): string | null => {
  switch (adSlot) {
    case BannerSlot.DONNONS_DETAIL_TOP:
      return '3518816560';
    case BannerSlot.DONNONS_LIST_ITEM:
      return '8771143245';
    case BannerSlot.DONNONS_PROFIL_MIDDLE:
      return '5297704616';
    case BannerSlot.DONNONS_HOME_SIDE:
      return '1714780304';
    case BannerSlot.DONNONS_LIST_SIDE:
      return '2588878273';
    case BannerSlot.DONNONS_DETAIL_SIDE:
      return '7266489880';
    case BannerSlot.DONNONS_PROFIL_SIDE:
      return '5761836525';
    default:
      return null;
  }
};

const AdPlaceholder: React.FC<Pick<AdProps, 'size'>> = ({ size }) => (
  <div className={`flex ${sizes[size].heightAndWidth} items-center justify-center bg-bg-pale text-[100px] font-bold text-bg-secondary`}>PUB</div>
);

const Ins: React.FC<AdProps & { id: string }> = ({ size, slot: adSlot, format: adFormat, id }) => {
  const ref = useRef<HTMLModElement>(null);
  const { env } = useEnvContext();
  const adTest = env === 'production' ? 'off' : 'on';

  const { currentPage } = useRouterContext();
  const path = currentPage.resolve();

  const inferredAdSlot = inferAdSlot(adSlot);

  useEffect(() => {
    if (path) {
      triggerAd();
    }
  }, [path]);

  if (!inferredAdSlot) {
    return null;
  }

  if (env !== 'production') {
    return <AdPlaceholder size={size} />;
  }

  return (
    <ins
      key={`ins-${adSlot}-${path}-${id}`}
      id={`ins-${adSlot}-${path}-${id}`}
      ref={ref}
      className={`${sizes[size].heightAndWidth} adsbygoogle block`}
      data-ad-client={GOOGLE_AD_CLIENT}
      data-ad-slot={inferredAdSlot}
      data-ad-format={adFormat ?? 'auto'}
      data-adtest={adTest}
      data-full-width-responsive="true"
    />
  );
};

export const Ad: React.FC<AdProps> = ({ size, slot: adSlot, format: adFormat }) => {
  const ref = useRef<HTMLDivElement>(null);

  const { currentPage } = useRouterContext();
  const path = currentPage.resolve();

  const { isLoaded } = useAdsContext();

  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    if (ref.current) {
      observe({
        root: document,
        el: ref.current,
        callback: inView => {
          if (inView) {
            setIsInView(true);
          }
        },
        rootMargin: '0px',
        threshold: 0,
      });
    }
  }, [ref, path]);

  const id = useId();

  return (
    <div key={`div-${adSlot}-${path}-${id}`} id={`div-${adSlot}-${path}-${id}`} ref={ref} className={`${sizes[size].heightAndWidth} block`}>
      {path && isInView && isLoaded && <Ins divId={`div-${adSlot}-${path}-${id}`} id={id} size={size} slot={adSlot} format={adFormat} />}
    </div>
  );
};

const AdSense: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [delay, setDelay] = useState(500);

  const computeIsLoaded = useCallback(() => {
    if (typeof window === 'object' && !isLoaded) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).adsbygoogle) {
        setIsLoaded(true);
        triggerAd(true);
      } else if (delay < 3000) {
        setDelay(delay + 500);
      }
    }
  }, [delay, isLoaded]);

  useTimeout(computeIsLoaded, delay);

  const value = useMemo(
    () => ({
      Ad,
      isLoaded,
      Interstitial: () => null,
    }),
    [isLoaded],
  );

  return (
    <AdsContext.Provider value={value}>
      <Head>
        <script id="adsense" async src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${GOOGLE_AD_CLIENT}`} crossOrigin="anonymous" />
      </Head>
      {children}
    </AdsContext.Provider>
  );
};

export default AdSense;
