import { useMemo } from 'react';

import useGlobalError from '@/core/hooks/api/useGlobalErrors';
import FavoritesDomain from '@/core/lib/new-architecture/domain/favorites.domain';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import type { StoreData } from '@/core/lib/new-architecture/store/store.types';

const useFavorites = (): StoreData<FavoritesDomain> => {
  const { requestToken, isLoggedIn } = Store.auth.useRequest();
  const { data: categories, isLoading: categoriesIsLoading } = Store.categories.useCategories();

  const { data: savedSearches, isLoading: savedSearchesIsLoading, error } = Query.savedSearch.useSavedSearches({ token: requestToken, enabled: !!isLoggedIn });

  const isLoading = savedSearchesIsLoading || categoriesIsLoading;

  useGlobalError(error);

  const data = useMemo(() => {
    if (!savedSearches || !categories) {
      return null;
    }

    return new FavoritesDomain({ savedSearches, categories });
  }, [savedSearches, categories]);

  return {
    data,
    isLoading,
    notFound: false,
  };
};

export default { useFavorites };
