import React, { forwardRef, useMemo } from 'react';

import Header from '@/core/components/Header';
import Icons from '@/core/components/Icons';
import { NewTextInput } from '@/core/components/inputs/TextInput';
import useSelectInput from '@/core/hooks/inputs/useSelectInput';
import { useIsLg } from '@/core/hooks/useMediaQuery';
import { searchAllCategories } from '@/core/lib/category';
import { useNativeContext } from '@/core/lib/native/native.context';
import type SuggestionDomain from '@/core/lib/new-architecture/domain/suggestion.domain';
import Store from '@/core/lib/new-architecture/store';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { NewInputProps, SelectItem } from '@/core/types/components';
import type { DonationsCategoryResult } from '@/core/types/donation';

export interface CategoryInputProps extends NewInputProps<DonationsCategoryResult | null> {
  onNotFound?: (search: string) => void;
  isFloating?: boolean;
  suggestions?: SuggestionDomain | null;
}

const find = (value: DonationsCategoryResult | null, data: SelectItem<DonationsCategoryResult>[]): SelectItem<DonationsCategoryResult> | null => {
  if (!value) return null;

  const firstLevel = data.find(item => item.value.id === value.id);

  if (!firstLevel) {
    const children = data.flatMap(item => item.children ?? []);

    if (children.length === 0) return null;

    return find(value, children);
  }

  return firstLevel;
};

const CategoryInput = forwardRef<HTMLInputElement, CategoryInputProps>(({ isFloating = true, ...props }, forwardedRef) => {
  const { t } = useTranslationContext(['common']);
  const { isNative } = useNativeContext();
  const { suggestions } = props;

  const { data: categories } = Store.categories.useCategories();

  const {
    id,
    isOpen,
    isSearching,
    onOpen,
    onClose,
    items,
    onSelect,
    onSearch,
    onClean,
    onBack,
    selectedItem,
    refs: { inputRef, divRef },
    ...rest
  } = useSelectInput<DonationsCategoryResult>({ forwardedRef, searchFn: searchAllCategories, findFn: find, ...props, items: categories?.getItems() ?? [] });

  const showSuggestions = !isSearching && suggestions && suggestions.categoriesDomainSuggested.length > 0;
  const highestAncestor = selectedItem ? categories?.findHighestAncestor(selectedItem.value.id) : null;
  const itemsWithoutChildren = useMemo(() => {
    const firstLevels = items.map(firstLevel => ({ ...firstLevel, children: [] }));
    if (showSuggestions) {
      return firstLevels.filter(firstLevel => !suggestions.categoriesDomainSuggested.some(suggestion => suggestion.value.id === firstLevel.value.id));
    }
    return firstLevels;
  }, [suggestions, showSuggestions, items]);

  const isLg = useIsLg();

  const mobileModalClasses = isOpen ? 'fixed top-0 left-0 h-screen w-screen z-50 bg-bg-primary px-4' : '';
  const dropdownClasses = `${isFloating ? 'lg:absolute' : ''} ${
    isOpen ? 'flex' : 'hidden'
  } lg:top-12 z-20 h-full lg:h-auto lg:max-h-select w-full min-w-67 flex-col overflow-auto rounded-2 bg-bg-primary pb-30 lg:pb-0 lg:p-3 lg:shadow-md lg:flex`;

  return (
    <div ref={isLg ? null : divRef} className={isLg ? 'relative' : mobileModalClasses}>
      {isOpen && !isLg && (
        <Header
          content={props.placeholder ?? ''}
          iconBefore={onBack ? 'arrow-left' : undefined}
          onBefore={onBack ?? undefined}
          labelBefore={onBack ? t('inputs.category.modal.back', { ns: 'common' }) : ''}
          iconAfter={!onBack ? 'close' : undefined}
          onAfter={onClose ?? undefined}
          labelAfter={!onBack ? t('inputs.category.modal.close', { ns: 'common' }) : ''}
        />
      )}
      <label htmlFor={`select-${id}`} className={isOpen && isNative && !isLg ? '[&>*:nth-child(2)]:my-4' : ''}>
        <span className="sr-only">{props.placeholder}</span>
        <NewTextInput
          {...rest}
          ref={inputRef}
          id={`select-${id}`}
          placeholder={props.placeholder}
          value={!highestAncestor ? '' : highestAncestor.label}
          onClick={!isLg && isOpen ? undefined : onOpen}
          onChange={onSearch}
          onClean={onClean}
          autoComplete="off"
        />
      </label>
      {(isOpen || !isLg) && (
        <div ref={isLg ? divRef : null} className={dropdownClasses}>
          {onBack && isLg && (
            <button
              type="button"
              className="text-body-secondary inline-flex cursor-pointer items-center justify-start gap-1 border-b border-stroke-tertiary bg-bg-primary p-3 text-start font-medium text-content-primary hover:bg-bg-pale"
              onClick={onBack}
            >
              <Icons icon="chevron-left" size="16" weight="bold" /> {t('inputs.category.modal.back', { ns: 'common' })}
            </button>
          )}
          {showSuggestions ? (
            <div className="mt-4 flex flex-col border-b border-stroke-tertiary">
              <p className="text-body-secondary font-semibold text-content-primary">{t('inputs.category.modal.suggestions', { ns: 'common' })}</p>
              <div className="my-2 flex flex-col">
                {suggestions.categoriesDomainSuggested.map(suggestion => (
                  <button
                    key={suggestion.value.id}
                    type="button"
                    className="text-body-secondary inline-flex cursor-pointer items-center justify-start gap-1 bg-bg-primary px-3 py-4 text-start font-medium text-content-primary hover:bg-bg-pale"
                    onClick={onSelect(suggestion)}
                  >
                    <Icons icon="tag" color="placeholder" size="20" weight="bold" />
                    {suggestion.value.label}
                  </button>
                ))}
              </div>
            </div>
          ) : null}
          {itemsWithoutChildren.length === 0 && <p className="text-body-secondary p-3 text-content-primary">{t('inputs.category.modal.categories.no-search-results', { ns: 'common' })}</p>}
          {!isSearching && itemsWithoutChildren.length > 0 && (
            <p className="text-body-secondary my-4 font-semibold text-content-primary">{t('inputs.category.modal.categories.title', { ns: 'common' })}</p>
          )}
          {itemsWithoutChildren.map(item => (
            <button
              key={item.value.id}
              type="button"
              className={`text-body-secondary inline-flex cursor-pointer items-center ${
                isSearching ? 'justify-start gap-1' : 'justify-between'
              } bg-bg-primary p-3 text-start font-medium text-content-primary hover:bg-bg-pale`}
              onClick={onSelect(item)}
            >
              {isSearching ? (
                <>
                  <span>
                    <Icons icon="search" color="placeholder" size="20" weight="bold" />
                  </span>
                  {categories?.getAncestryString(item.value.id)}
                </>
              ) : (
                ''
              )}{' '}
              {item.text} {item.children && item.children.length > 0 && <Icons icon="chevron-right" size="16" weight="bold" />}
            </button>
          ))}
        </div>
      )}
    </div>
  );
});

export default CategoryInput;
