import React from 'react';

import Button from '@/core/components/Button';
import Icons from '@/core/components/Icons';
import useMutation from '@/core/hooks/useMutation';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useBrowserContext } from '@/core/lib/browser/browser.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import { QUERY_KEYS } from '@/core/lib/query/query.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

const GoogleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" fill="none" className="size-5">
    <path
      d="M5.00005 12.0862L4.30388 14.6852L1.75938 14.739C0.998955 13.3286 0.567627 11.7148 0.567627 10C0.567627 8.34176 0.970908 6.77801 1.68575 5.40109H1.6863L3.95161 5.81641L4.94396 8.06812C4.73626 8.67363 4.62306 9.32363 4.62306 10C4.62314 10.7341 4.7561 11.4374 5.00005 12.0862Z"
      fill="#FBBB00"
    />
    <path
      d="M20.3929 8.13187C20.5077 8.73679 20.5676 9.36151 20.5676 9.99999C20.5676 10.7159 20.4924 11.4143 20.349 12.0879C19.8622 14.3802 18.5902 16.3819 16.8281 17.7984L16.8276 17.7978L13.9743 17.6522L13.5704 15.1313C14.7397 14.4456 15.6534 13.3725 16.1347 12.0879H10.7875V8.13187H16.2127H20.3929Z"
      fill="#518EF8"
    />
    <path
      d="M16.8275 17.7978L16.8281 17.7983C15.1144 19.1758 12.9374 20 10.5676 20C6.7594 20 3.44842 17.8714 1.7594 14.739L5.00006 12.0862C5.84456 14.3401 8.01874 15.9445 10.5676 15.9445C11.6632 15.9445 12.6896 15.6483 13.5704 15.1313L16.8275 17.7978Z"
      fill="#28B446"
    />
    <path
      d="M16.9508 2.30219L13.7112 4.95437C12.7997 4.38461 11.7222 4.05547 10.5678 4.05547C7.96119 4.05547 5.74634 5.73348 4.94416 8.06812L1.68646 5.40109H1.68591C3.35021 2.1923 6.70294 0 10.5678 0C12.9942 0 15.2189 0.864297 16.9508 2.30219Z"
      fill="#F14336"
    />
  </svg>
);

const GoogleButton: React.FC = () => {
  const { t } = useTranslationContext(['common', 'auth']);
  const { DONNONS_API_URL } = useEnvContext();
  const { open } = useBrowserContext();
  const { closeAuth } = useAuthContext();
  const { send } = useAnalyticsContext();

  const mutation = useMutation<string, void>({
    apiDefinition: { method: ApiMethods.GET, url: `${DONNONS_API_URL}${ApiVersions.V1}${QUERY_KEYS.authGoogle().join('/')}` },
  });

  const onClick = async () => {
    await send({ event: AnalyticsEventType.CLICK_LOGIN, origin: 'google' });
    const res = await mutation.mutateAsync();
    await open(res);
    closeAuth();
  };

  return (
    <Button variant="gaf" onClick={onClick}>
      <span className="mr-2">{mutation.isPending ? <Icons icon="spinner" color="button-default" /> : <GoogleIcon />}</span>
      {t('google-button', { ns: 'auth' })}
    </Button>
  );
};

export default GoogleButton;
