import type CategoriesDomain from '@/core/lib/new-architecture/domain/categories.domain';
import SavedSearchDomain from '@/core/lib/new-architecture/domain/savedSearch.domain';
import type { SavedSearchesResult } from '@/core/types/favorite';

export interface FavoritesDomainConstructor {
  savedSearches: SavedSearchesResult;
  categories: CategoriesDomain;
}

export interface FavoritesDomainInterface {
  getSavedSearches: () => SavedSearchDomain[];
  getSavedSearch: (hash: string) => SavedSearchDomain | null;
  hasSavedSearch: (hash: string) => boolean;
  getUnread: () => number;
}

type SavedSearchesMap = Map<string, SavedSearchDomain>;

class FavoritesDomain implements FavoritesDomainInterface {
  private savedSearchesMap: SavedSearchesMap;

  constructor({ savedSearches, categories }: FavoritesDomainConstructor) {
    const savedSearchesMap = new Map<string, SavedSearchDomain>([]);

    savedSearches.searches.forEach(savedSearch => {
      savedSearchesMap.set(savedSearch.hash, new SavedSearchDomain({ data: savedSearch, categories }));
    });

    this.savedSearchesMap = savedSearchesMap;
  }

  public getSavedSearches(): SavedSearchDomain[] {
    return Array.from(this.savedSearchesMap.values());
  }

  public getSavedSearch(hash: string): SavedSearchDomain | null {
    return this.savedSearchesMap.get(hash) ?? null;
  }

  public hasSavedSearch(hash: string): boolean {
    return this.savedSearchesMap.has(hash);
  }

  public getUnread(): number {
    return Array.from(this.savedSearchesMap.values()).reduce((unread, savedSearch) => unread + savedSearch.getUnread(), 0);
  }
}

export default FavoritesDomain;
