import * as yup from 'yup';

export const homeResultSchema = yup.object({
  apple_store: yup.string().required(),
  play_store: yup.string().required(),
  time: yup.string().required(),
  nb_users_created: yup.number().required(),
});

export type HomeResult = yup.InferType<typeof homeResultSchema>;
